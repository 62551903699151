import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { IoIosArrowForward } from "react-icons/io";

const ContactItems = ({ contactItems }) => {
	return (
		<section className="pt-8">
			<Container>
				<Row className={`align-items-start`}>
					{contactItems.map((item, i) => (
						<Col lg={3} key={i} className="px-4 mb-5 mb-lg-0">
							<GatsbyImage
								image={
									item.contactCtaItemIcon.node?.localFile.childImageSharp
										.gatsbyImageData
								}
								alt={item.contactCtaItemIcon.node?.altText}
							/>
							<h5 className="text-white fs-6 pt-4 montserrat-black">
								{item.contactCtaItemHeading}
							</h5>
							<p className="text-white fs-6 mb-2">
								{item.contactCtaItemDescription}
							</p>
							{item.contactCtaItemLink && (
								<>
									{item.contactLocation == "No" ? (
										<a class="text-white" href={item.contactCtaItemLink.url}>
											{item.contactCtaItemLink.title}
										</a>
									) : (
										<a
											class="text-white text-underline-hover mt-4 d-flex align-items-center"
											href={item.contactCtaItemLink.url}
										>
											{item.contactCtaItemLink.title}
											<IoIosArrowForward className="fs-5 ms-2" />
										</a>
									)}
								</>
							)}
						</Col>
					))}
				</Row>
			</Container>
		</section>
	);
};

export default ContactItems;
