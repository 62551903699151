import React, { useState, useEffect } from "react";
import { useForm, ValidationError } from "@formspree/react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

function ContactForm() {
	const [state, handleSubmit] = useForm("mvoeleqb");
	const [gclid, setGclid] = useState(""); // New state to store gclid

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const gclidParam = urlParams.get("gclid");
		if (gclidParam) {
			setGclid(gclidParam); // Store the gclid in state if it exists
		}
	}, []);

	const handleFormSubmit = (event) => {
		event.preventDefault(); // Prevent default form submission

		const formData = new FormData(event.target);
		const customerEmail = formData.get("email");
		const customerPhone = formData.get("telephone");
		const customerName = formData.get("name");

		// Push to dataLayer after form submit
		if (typeof window !== "undefined" && window.dataLayer) {
			window.dataLayer.push({
				event: "generate_lead",
				form_name: "ContactForm", // You can change this based on the form name
				customer_email: customerEmail,
				customer_phone_number: customerPhone,
			});
		}

		handleSubmit(event); // Proceed with Formspree submission
	};

	if (state.succeeded) {
		window.location.href = "/thank-you";
	}

	return (
		<Form className="w-100" onSubmit={handleFormSubmit}>
			<Row>
				<Col lg={6}>
					<Form.Group className="mb-3" controlId="name">
						<Form.Label className="montserrat-bold">First name</Form.Label>
						<Form.Control
							name="name"
							type="text"
							style={{ height: "48px" }}
							required
						/>
					</Form.Group>
					<ValidationError prefix="Name" field="name" errors={state.errors} />
				</Col>
				<Col lg={6}>
					<Form.Group className="mb-3" controlId="surname">
						<Form.Label className="montserrat-bold">Surname</Form.Label>
						<Form.Control
							name="surname"
							type="text"
							style={{ height: "48px" }}
							required
						/>
					</Form.Group>
					<ValidationError
						prefix="Surname"
						field="surname"
						errors={state.errors}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg={6}>
					<Form.Group className="mb-3" controlId="jobTitle">
						<Form.Label className="montserrat-bold">Job Title</Form.Label>
						<Form.Control
							type="text"
							name="jobTitle"
							style={{ height: "48px" }}
							required
						/>
					</Form.Group>

					<ValidationError
						prefix="JobTitle"
						field="jobTitle"
						errors={state.errors}
					/>
				</Col>
				<Col lg={6}>
					<Form.Group className="mb-3" controlId="company">
						<Form.Label className="montserrat-bold">Company</Form.Label>
						<Form.Control
							name="company"
							type="text"
							style={{ height: "48px" }}
							required
						/>
					</Form.Group>

					<ValidationError
						prefix="Company"
						field="company"
						errors={state.errors}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form.Group className="mb-3" controlId="telephone">
						<Form.Label className="montserrat-bold">Email address</Form.Label>
						<Form.Control
							type="email"
							name="email"
							style={{ height: "48px" }}
							required
						/>
					</Form.Group>

					<ValidationError prefix="Email" field="email" errors={state.errors} />
				</Col>
			</Row>
			<Row>
				<Col>
					<div className="my-3">
						<span className="montserrat-bold fs-6">Location:</span>
						<Form.Check
							inline
							label="UK"
							name="location"
							type={"radio"}
							id={`inline-radio-1`}
							className="mx-4"
							value="UK"
						/>
						<Form.Check
							inline
							label="Elsewhere in the world"
							name="location"
							type="radio"
							id={`inline-radio-2`}
							value="Elsewhere in the world"
						/>
					</div>
				</Col>
			</Row>
			<Row className="mt-4">
				<Col>
					<Form.Group className="mb-3" controlId="message">
						<Form.Label className="montserrat-bold">Message</Form.Label>
						<Form.Control
							as="textarea"
							name="message"
							placeholder="Type your Message"
							rows={4}
						/>
					</Form.Group>

					<ValidationError
						prefix="message"
						field="message"
						errors={state.errors}
					/>
				</Col>
			</Row>

			{/* Hidden field for gclid */}
			<Form.Group controlId="gclid" className="d-none">
				<Form.Control type="hidden" name="gclid" value={gclid} />
			</Form.Group>

			<Row>
				<Col>
					<div className="mt-3">
						<Form.Check
							inline
							label="I accept the Terms"
							name="acceptTerms"
							type={"checkbox"}
							required
							value="True"
						/>
					</div>
				</Col>
			</Row>

			<Button
				size="small"
				className="px-4 py-3 mt-5 montserrat-bold fs-6 btn btn-secondary w-100 w-md-auto"
				type="submit"
				id="contact-send-btn"
				disabled={state.submitting}
			>
				Submit
			</Button>
		</Form>
	);
}

export default ContactForm;
