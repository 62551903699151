import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import ContactForm from "../components/contact-form";
import ContactItems from "../components/contact-item";
import Layout from "../components/layout";
import bgImage from "../images/why-choose-bg.png";

const ContactUsPage = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "contact" }) {
				seoFields {
					localBusinessSchema
					metaDescription
					opengraphDescription
					opengraphTitle
					title
					image {
						node {
							altText
							sourceUrl
							mediaDetails {
								height
								width
							}
						}
					}
				}
				contactPage {
					contactCtaSection {
						contactCtaSectionHeading
						contactCtaSectionSubHeading
						contactCtaSectionContent
						contactCtaItems {
							contactCtaItemHeading
							contactCtaItemDescription
							contactLocation
							contactCtaItemIcon {
								node {
									altText
									sourceUrl
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: WEBP
												quality: 80
												transformOptions: { cropFocus: CENTER, fit: COVER }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
							contactCtaItemLink {
								title
								target
								url
							}
						}
					}
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const {
		pageData: { seoFields, contactPage },
	} = data;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Contact Us",
				item: {
					url: `${siteUrl}/contact-us`,
					id: `${siteUrl}/contact-us`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/contact-us`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.sourceUrl}`,
							width: `${seoFields?.image?.node.mediaDetails.width}`,
							height: `${seoFields?.image?.node.mediaDetails.height}`,
							alt: `${seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>

			<Layout isDefault={false}>
				<section className="bg-blue-bg py-5 py-md-8" style={sectionStyle()}>
					<Container>
						<Row className="align-items-lg-center">
							<Col lg={7} className="mb-4 mb-lg-0 text-start">
								<span className="text-white">
									{contactPage.contactCtaSection.contactCtaSectionSubHeading}
								</span>
								<h2 className="my-auto text-white pb-2">
									{contactPage.contactCtaSection.contactCtaSectionHeading}
								</h2>
								<div
									dangerouslySetInnerHTML={{
										__html:
											contactPage.contactCtaSection.contactCtaSectionContent,
									}}
									className="fw-light text-white text-start"
								></div>
							</Col>
						</Row>
						<Row className="align-items-lg-center justify-content-lg-center mt-5">
							<Col lg={6}>
								<div className="bg-white p-5 rounded-5">
									<ContactForm />
								</div>
							</Col>
						</Row>
						<ContactItems
							contactItems={contactPage.contactCtaSection.contactCtaItems}
						/>
					</Container>
				</section>
			</Layout>
		</>
	);
};
export const sectionStyle = () => ({
	background: `url(${bgImage}) no-repeat`,
	backgroundPosition: "top right",
	backgroundSize: "40%",
});

export default ContactUsPage;
